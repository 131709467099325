<script setup>
import { ref, getCurrentInstance, computed } from 'vue'
import { DriverStore } from '../pinia/Driver.js'

const { proxy } = getCurrentInstance()
const liveries = ref([])  // All liveries from API
const carList = ref([])  // List of cars fetched from the API
const carfiltre = ref('')  // Selected car for filtering
const sortOrder = ref('desc')  // Sort order, default is descending
const selectedImage = ref(null)  // Stocke l'image sélectionnée pour la popup
const isImagePopupOpen = ref(false)  // Contrôle l'ouverture de la popup
const dialogWidth = ref('auto'); // Largeur dynamique du dialogue
const dialogHeight = ref('auto'); // Hauteur dynamique du dialogue
const imageStyles = ref({});
const isEditMode = ref(false)
const driver = DriverStore()

// Fetch existing liveries
async function getLiveries() {
  const res = await proxy.$api({ serviceName: "liveries", methodName: "getLiveries" })
  liveries.value = res
}

const selectedLivery = ref({ imageUrl: '', title: '', code: '' }); // Store livery data for the popup

function openImagePopup(livery) {
  const img = new Image();
  img.onload = () => {
    const imgWidth = img.width;
    const imgHeight = img.height;

    // Set dialog size dynamically based on image dimensions
    dialogWidth.value = imgWidth + 'px';
    dialogHeight.value = imgHeight + 'px';

    // Open the popup with livery details
    selectedLivery.value = {
      imageUrl: 'https://cdn.nightriderz.world/images/website/liveries/' + livery.code + '.png',
      title: livery.liveryname,
      code: livery.code,
      author: livery.personaName,
      car: livery.carFullName
    };
    isImagePopupOpen.value = true;
  };
  img.src = 'https://cdn.nightriderz.world/images/website/liveries/' + livery.code + '.png';
}


// Fetch the list of cars for filtering
getCarClasses()
async function getCarClasses() {
  const res = await proxy.$api({ serviceName: "liveries", methodName: "getCarClasses" })
  if (res && Array.isArray(res)) {
    carList.value = res;  // No need to map, API already returns the correct structure
  }
}

const isFormOpen = ref(false)
const isHowToOpen = ref(false)  // State for the "how to" popup
const form = ref({
  liveryName: '',
  liveryCode: '',
  liveryImage: null, // The selected file will be stored here
})

const previewImage = ref(null) // Stores the image URL for preview
const errorMessage = ref('')

function publishLivery() {
  resetForm();
  isFormOpen.value = true;
}

// Open "how to" dialog
function openHowTo() {
  isHowToOpen.value = true;
}

// Fetch liveries by selected carname (store_name)
async function getLiveriesByCar(carname) {
  const res = await proxy.$api({ serviceName: "liveries", methodName: "getLiveriesByCar", parameters: [carname] })
  liveries.value = res;
}

// Apply the car filter and fetch filtered liveries from the API
function CarFilter(option) {
  if (option) {
    carfiltre.value = option.store_name;
    getLiveriesByCar(carfiltre.value);
  } else {
    carfiltre.value = '';
    getLiveries();
  }
}

function openEditForm(livery) {
  resetForm();
  if (livery.personaId === driver.persona.ID) {
    form.value.liveryName = livery.liveryname
    form.value.liveryCode = livery.code  // Préremplir le code de la livrée
    isEditMode.value = true  // Mode édition
    isFormOpen.value = true
  } else {
    console.log('Vous ne pouvez pas éditer cette livrée.')
  }
}

function handleImageSelected(event) {
  const file = event.target.files[0];

  if (file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      previewImage.value = e.target.result;

      const img = new Image();
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        const ratio = (width / height).toFixed(2);
        const expectedRatio = (16 / 9).toFixed(2);

        if (ratio !== expectedRatio) {
          errorMessage.value = "Please select an image with a 16:9 ratio."
          previewImage.value = null;
        } else {
          errorMessage.value = '';
        }
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
    form.value.liveryImage = file;
  } else {
    previewImage.value = null;
  }
}

function toggleSortOrder() {
  sortOrder.value = sortOrder.value === 'desc' ? 'asc' : 'desc';
}

// Computed property for sorted liveries
const sortedLiveries = computed(() => {
  return [...liveries.value].sort((a, b) => {
    if (sortOrder.value === 'asc') {
      return a.useCount - b.useCount;
    } else {
      return b.useCount - a.useCount;
    }
  });
});

function resetForm() {
  form.value = {
    liveryName: '',
    liveryCode: '',
    liveryImage: null,
  };
  previewImage.value = null;
  errorMessage.value = '';
  isEditMode.value = false;
}

function uploadLivery(e) {
  e.preventDefault();

  if (!form.value.liveryName || !form.value.liveryCode) {
    errorMessage.value = 'Veuillez fournir toutes les informations requises.';
    return;
  }

  // En mode création, l'image est obligatoire
  if (!isEditMode.value && !form.value.liveryImage) {
    errorMessage.value = 'Veuillez sélectionner une image pour la nouvelle livrée.';
    return;
  }

  const fd = new FormData();

  // Ajouter l'image uniquement si elle est présente (non obligatoire en mode édition)
  if (form.value.liveryImage) {
    fd.append("file", form.value.liveryImage);
  }

  fd.append('easharpptr-u', localStorage.getItem('easharpptr_u'));
  fd.append('easharpptr-p', localStorage.getItem('easharpptr_p'));
  fd.append('livery_code', form.value.liveryCode);
  fd.append('livery_name', form.value.liveryName);

  const url = isEditMode.value 
    ? 'https://api.nightriderz.world/update_livery.php'
    : 'https://api.nightriderz.world/upload_livery.php';

  var XHR = new XMLHttpRequest();
  XHR.onreadystatechange = function () {
    if (this.readyState == 4) {
      if (this.status == 200) {
        const response = JSON.parse(this.responseText);
        if (response.success) {
          getLiveries();  // Recharge les livrées après succès
          isFormOpen.value = false;
          previewImage.value = null;
        } else {
          errorMessage.value = response.message;
        }
      } else {
        errorMessage.value = 'Erreur lors de l\'envoi de la livrée.';
      }
    }
  };

  XHR.open('POST', url);
  XHR.send(fd);
}

function openFile() {
  document.querySelector('.file').click();
}

// Initial call to load liveries and car list
getLiveries();
getCarClasses();
</script>

<template>
  <v-app-bar :elevation="2" style="overflow: initial;">
    <v-icon icon="mdi-palette"></v-icon>
    <v-app-bar-title>Liveries</v-app-bar-title>
    <v-select placeholder="Choose a car to filter results" class="search" density="compact" solo label="full_name" :options="carList" item-text="full_name" item-value="store_name" style="height: 32px;width: 420px;" @update:modelValue="CarFilter">
      <template v-slot:option="option">
        <div class="d-flex" style="justify-content: center;align-items: center;">
          <span style="width: 340px;text-overflow: ellipsis;overflow: hidden;text-transform: uppercase;">{{ option.full_name }}</span>
          <v-spacer></v-spacer>
                <v-chip size="small">{{ number_format(option.livery_count)}}
                </v-chip>
        </div>
      </template>
    </v-select>

    <v-btn size="small" @click="toggleSortOrder" style="background: #1f2430;border-radius: 4px;width: 32px; height: 32px; margin-left: 8px; min-width: 32px">
      <v-icon style="font-size: 20px;">{{ sortOrder === 'desc' ? 'mdi-sort-descending' : 'mdi-sort-ascending' }}</v-icon>
      <v-tooltip activator="parent" location="bottom">Invert sort order</v-tooltip>
    </v-btn>
    <!-- Publish Button -->
    <v-btn size="small" style="background: #1f2430; border-radius: 4px; width: 32px; height: 32px; margin-left: 8px; min-width: 32px" @click="publishLivery">
      <v-icon style="font-size: 20px;">mdi-publish</v-icon>
      <v-tooltip activator="parent" location="bottom">Publish a livery</v-tooltip>
    </v-btn>

    <!-- How to Button -->
    <v-btn size="small" style="background: #1f2430;border-radius: 4px;width: 32px; height: 32px; margin-left: 8px; min-width: 32px" @click="openHowTo">
      <v-icon style="font-size: 20px;">mdi-help-circle-outline</v-icon>
      <v-tooltip activator="parent" location="bottom">How to use</v-tooltip>
    </v-btn>
  </v-app-bar>

  <!-- Publish Livery Dialog -->
  <v-dialog v-model="isFormOpen" max-width="600px">
    <v-card>
      <v-toolbar dark color="primary">
        <v-icon>mdi-palette</v-icon>
        <v-toolbar-title>{{ isEditMode ? 'Edit a livery' : 'Publish a livery' }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form @submit.prevent="uploadLivery">
          <v-alert v-if="errorMessage" type="error">{{ errorMessage }}</v-alert>
          <v-text-field v-model="form.liveryName" label="Livery name" variant="outlined" required prepend-icon="mdi-tag-text"></v-text-field>
          <v-text-field v-model="form.liveryCode" label="Livery code" required variant="outlined" prepend-icon="mdi-qrcode" @input="form.liveryCode = form.liveryCode.toUpperCase()" :rules="[v => /^[A-Z0-9]{3}-[A-Z0-9]{3}-[A-Z0-9]{3}$/.test(v) || 'The code must be in the format ABC-123-DEF']" :disabled="isEditMode"></v-text-field>
          <input type="file" id="fileupload" class="file" @change="handleImageSelected" hidden>
          <img v-if="previewImage" :src="previewImage" alt="Image Preview" style="border-radius: 4px !important;position: fixed!important;width: 100px;top: 4px;right: 4px;"></img>
          <div class="d-flex justify-end">
            <v-btn @click="openFile" variant="tonal">Select an Image</v-btn>
            <v-btn type="submit" style="margin-left: 6px;background: rgb(38, 107, 173);">{{ isEditMode ? 'Save Changes' : 'Publish' }}</v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>


  <!-- How to Dialog -->
  <v-dialog v-model="isHowToOpen" max-width="550px">
    <v-card>
      <v-toolbar dark color="primary">
        <v-icon>mdi-help-circle-outline</v-icon>
        <v-toolbar-title>Livery system usage</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <h3>How to export and publish a livery?</h3>
        <ol>
          <li>In game, pick the car that has the livery you want to export.</li>
          <li>Open the chat and type <span style="background: black;font-size:14px;font-weight: 600;border-radius: 4px;"> /livery export </span> to get the <strong>code</strong>.</li>
          <li>Click on the <strong>upload</strong> button that is next to the car filter.</li>
          <li>Fill the <strong>form</strong> as it is indicated.</li>
          <li>Select an image of <strong>16:9 aspect ratio</strong> of your livery.</li>
          <li>Once all details are filled, click <strong>Publish</strong> to publish the livery.</li>
          <br>
          <span>Only the owner of a code can publish the livery linked to it.</span>
          <br>
          <span>The ideal image resolution is <span style="background: black;font-size:14px;font-weight: 600;border-radius: 4px;"> 600 x 338 </span>.</span>
        </ol>
        <br>
        <h3>How to install a livery?</h3>
        <ol>
          <li>In game, pick the car that you want to equip of a livery.</li>
          <li>Find the livery <strong>code</strong> on the website.</li>
          <li>Open the chat and execute the command <span style="background: black;font-size:14px;font-weight: 600;border-radius: 4px;"> /livery import 123-ABC-456 </span> to install the chosen <strong>livery</strong>.</li>
          <li>Leave or go to <strong>safehouse</strong> in order to refresh the car look.</li>
          <br>
          <span>In order to install a <strong>livery</strong> on another car model than the one it is made for, use the command suffix <span style="background: black;font-size:14px;font-weight: 600;border-radius: 4px;"> --force </span>.</span>
        </ol>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="isHowToOpen = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="isImagePopupOpen" min-width="300px" max-width="800px" style="border-radius:8px">
  <v-card style="border-radius:8px">
    <v-img :src="selectedLivery.imageUrl" :style="imageStyles" style="border-radius:8px"></v-img>
    <div class="d-flex" style="justify-content: center;align-items: center;margin-left: 8px;">
      <v-card class="card" style="text-align: center; position: absolute; margin-left: auto; margin-right: auto; padding: 0 6px 0px 6px; bottom: -15px;">
        <span><b>{{ selectedLivery.title }}</b> by <b>{{ selectedLivery.author }}</b></span>
      </v-card>
      <!--<v-card class="card" style="text-align: center;position:absolute; margin-left: auto; margin-right: auto;margin-top: -40px; padding: 0 6px 0px 6px">
        <span>{{ selectedLivery.code }}</span>
      </v-card>
      <v-card class="card" style="text-align: center;position:absolute; margin-left: auto; margin-right: auto;margin-top: -40px; padding: 0 6px 0px 6px">
        <span>{{ selectedLivery.personaName }}</span>
      </v-card>
      <v-card class="card" style="text-align: center;position:absolute; margin-left: auto; margin-right: auto;margin-top: -40px; padding: 0 6px 0px 6px">
        <span>{{ selectedLivery.car }}</span>
      </v-card>-->
    </div>
  </v-card>
</v-dialog>

  <!-- Display of filtered liveries -->
  <div class="grid">
    <v-card v-for="(livery, index) in sortedLiveries" :key="index" class="card" width="310">
      <v-card style="background: var(--primary-color);width:auto;position:absolute;z-index: 999;font-size: 14px;font-weight: 600;margin-top: 12px;margin-left: 12px;border-radius: 2px;padding-right: 6px;">
        <v-icon style="font-size: 18px;margin-left: 2px;" icon="mdi-download"></v-icon> {{ livery.useCount }}
      </v-card>
      <v-btn icon="mdi-pencil" v-if="livery.personaId === driver.persona.ID" @click="openEditForm(livery)" style="position:absolute;z-index: 999;background: var(--primary-color);width: 24px; height: 24px;right:12px;top:12px;font-size: 12px;">
    </v-btn>
      <img :src="'https://cdn.nightriderz.world/images/website/liveries/' + livery.code + '.png'" class="card-image" @click="openImagePopup(livery)"></img>
      <v-card-subtitle style="background: #1f2430; margin-left: 6px; margin-right: 6px; margin-top: 6px; border-radius: 4px;justify-content: left;">
        <v-icon style="position: absolute;left:16px">mdi-palette</v-icon><span style="margin-left: 36px;text-transform: uppercase;">{{ livery.liveryname }}</span>
      </v-card-subtitle>
      <v-card-subtitle style="background: #1f2430; margin-left: 6px; margin-right: 6px; margin-top: 6px; border-radius: 4px;justify-content: left;">
        <v-icon style="position: absolute;left:16px">mdi-car</v-icon><span style="margin-left: 36px;">{{ livery.carFullName }}</span>
      </v-card-subtitle>
      <v-card-subtitle style="background: #1f2430; margin-left: 6px; margin-right: 6px; margin-top: 6px; border-radius: 4px;justify-content: left;">
        <v-icon style="position: absolute;left:16px">mdi-qrcode</v-icon><span style="margin-left: 36px;">{{ livery.code }}</span>
        <v-tooltip color="#11141c" activator="parent" location="bottom">Type in the game chat<br>/livery import {{ livery.code }}</v-tooltip>
      </v-card-subtitle>
      <v-card-subtitle style="background: #1f2430; margin-left: 6px; margin-right: 6px; margin-top: 6px; border-radius: 4px;justify-content: left;">
        <v-icon style="position: absolute;left:16px">mdi-racing-helmet</v-icon><span style="margin-left: 36px;">{{ livery.personaName }}</span>
      </v-card-subtitle>
    </v-card>
  </div>
</template>

<style scoped>
.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 3400px;
  margin: 0 auto;
}

.v-card-subtitle {
  background: var(--primary-color);
  display: flex;
  justify-content: center;
  padding: 10px 0;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 6px;
  opacity: 1;
}

.v-select::v-deep(li) {
  margin: 0;
  overflow: hidden;
}

.v-select::v-deep(v-chip) {
  margin: 0;
  padding: 0;
}

.v-select::v-deep(button) {
  background: transparent;
}

.search {
  width: 450px;
  background: rgb(var(--v-theme-surface));
  border-radius: 4px;
  padding-right: 3px;
}

.card {
  background: var(--primary-color);
  margin: 25px;
}

.card-image {
  width: 298px;
  height: 169px;
  object-fit: cover;
  top: 6px;
  left: 6px;
  position: relative;
  border-radius: 4px;
}

.v-img__img.v-img__img--contain {
  position: fixed !important;
}
</style>
